import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const Otp = ({email, setEmail, sendOtp}) => {
    return (
        <div>
            <FormControl fullWidth style={{margin: '15px 0'}}>
                <TextField value={email} onChange={(e) => setEmail(e.target.value)} label="Email" variant="outlined" />
            </FormControl>
            <p style={{marginBottom: '20px', opacity: '.5'}} className='text'>Use mobile number given in bookong.</p>
            <button onClick={sendOtp} className='btn btn-full btn-gradient'>Get OTP</button>
        </div>
    )
}

export default Otp;