import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';


import { useState, useEffect } from 'react';
import { createCheckout, verifyCheckOut, applyCoupan, getSelectedPlan } from '../../http';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { validateEmail, validateMobileNumber } from '../../tool/validatoe';

const Review = ({
    step,
    setStep,
    decoration, 
    cake, 
    addon,
    numberOfPerson,
    date,
    amount,
    slot,
    name,
    setName,
    email,
    setEmail,
    mobile,
    setMobile,
    plan,
    city,
    eggless
}) => {

    const [totalAmount, setTotalAmount] = useState(amount);
    const [coupan_code, setCoupan] = useState('');
    const [discount, setDiscount] = useState(0);
    const [coupanApplied, setCoupanApplied] = useState(false)

    const [selectedPlan, setSelectedPlan] = useState({features: []})

    useEffect(() => {
        const fetchSelectedPlan = async () => {
            try {
                const {data} = await getSelectedPlan(plan)
                setSelectedPlan(data.plan)
                
                
            } catch (error) {
                console.log(error)
            }
        }
        fetchSelectedPlan()
    }, [plan])

    const navigate = useNavigate();

    useEffect(() => {
        
        let addonAmount = addon.reduce((sum, current) => sum + current.price, 0);
        let eggPrice = eggless ? cake.eggless_price : cake.price;
        setTotalAmount(amount + addonAmount + decoration.price + eggPrice);
    
    }, [])

    const [selectedSlot, setSelectedSlot] = useState(JSON.parse(slot))

    useEffect(() => {
        window.scrollTo(0, 0)
      },[])

   
    const onNext = async () => {

        if(!name){

            toast.error('Name is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }
        if(!validateEmail(email)){
            toast.error('Invalid Email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }
        if(!validateMobileNumber(mobile)){

            toast.error('Invalid Mobile Number', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }



        try {

            const {data} = await toast.promise(
                createCheckout({
                date,
                city,
                plan,
                number_of_person: numberOfPerson,
                slot: selectedSlot,
                decoration,
                cake,
                addons: addon,
                name,
                email,
                mobile,
                amount: totalAmount,
                paid_amount: +totalAmount / 2,
                coupan_code: coupan_code,
                discount: discount

            }),
            {
                pending: 'Redirecting to payment page',
                error: 'Redirection failed 🤯'
            }
        )
        window.location = data.data.instrumentResponse.redirectInfo.url
            
        } catch (error) {
            console.log(error)
        }

    }

    const handleApplyCoupan = async () => {

        try {
            const {data} = await toast.promise(
                applyCoupan({coupan_code, total_amount: totalAmount}),
                {
                  pending: 'Please wait..',
                  success: 'Coupan Applied Successfully',
                }

            )
            setDiscount(Math.round(data.discount));
            setCoupanApplied(true)

            console.log(data)
            
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }

    }

   

    return (

        <div>

<section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Checkout</h1>
                   </div>
                </div>
            </section>


        <section id="review" className="step-p-section">
            <div className="my-container">
            <div className="row">
             <div className='col'>
             <div className="widget">
                <h2 class="sub-heading color-primary" style={{marginBottom: '15px'}}>{selectedPlan.title}</h2>
                    <ul className="verticle-list review-list">
                        <li>
                           <i style={{color: '#7f43ea', fontSize: '30px',}} class="fa-solid fa-indian-rupee-sign"></i><span className="text primary-color review-text">₹: {totalAmount - discount} (inclusive of all taxes)</span>
                        </li>

                        <li>
                          <i style={{color: '#7f43ea', fontSize: '30px',}} class="fa-solid fa-calendar-days"></i><span className="text primary-color review-text">{dayjs(date).format('D MMMM, YYYY')}</span>
                        </li>
                        <li>
                           <i class="fa-solid fa-clock" style={{color: '#7f43ea', fontSize: '30px',}} ></i><span className="text primary-color review-text">{selectedSlot.from} - {selectedSlot.to}</span>
                        </li>
                        <li>
                          <i class="fa-solid fa-user" style={{color: '#7f43ea', fontSize: '30px',}}></i><span className="text primary-color review-text">{ numberOfPerson}</span>
                        </li>
                        {cake._id &&<li>
                           <i class="fa-solid fa-cake-candles" style={{color: '#7f43ea', fontSize: '30px',}}></i><span className="text primary-color review-text">{cake.title}</span>
                        </li>}
                        {decoration._id && (<li>
                          <i class="fa-solid fa-gift" style={{color: '#7f43ea', fontSize: '30px',}}></i><span className="text primary-color review-text">{decoration.title}</span>
                        </li>)}
                    </ul>
                    {addon.length > 0 && (<div className="addon-review">
                        <p className="accen-heading" style={{color: '#000', marginBottom: '10px'}}>Add-on</p>
                        <div className="addon-review-inner">
                           {
                            addon.map(add =>  <p className="addont-review-tag text light">{add.title} (₹:{add.price})</p>)
                           }
                        </div>
                    </div>)}
                </div>
             </div>

             <div className='col'>
             <div className="widget">

<h2 class="sub-heading color-primary" style={{marginBottom: '15px'}}>Enter Your Detail To Proceed</h2>

<FormControl fullWidth style={{margin: '10px 0'}}>
    <TextField defaultValue={name} onChange={(e) => setName(e.target.value)} sx={{color: 'red'}} label="Name" variant="outlined" />
</FormControl>

<FormControl fullWidth style={{margin: '10px 0'}}>
    <TextField defaultValue={mobile} onChange={(e) => setMobile(e.target.value)} label="Mobile" variant="outlined" />
</FormControl>

<FormControl fullWidth>
    <TextField label="Email" type='email' defaultValue={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" />
</FormControl>

</div>
<div className='widget'>
  <h2 class="sub-heading color-primary" style={{marginBottom: '15px'}}>Do you have a coupan?</h2>
 <div style={{display: 'flex', alignItems: 'center'}}>
 <FormControl fullWidth style={{margin: '10px 20px 10px 0'}}>
    <TextField disabled={coupanApplied ? true : false} defaultValue={coupan_code} onChange={(e) => setCoupan(e.target.value)} sx={{color: 'red'}} label="Coupan Code" variant="outlined" />
 </FormControl>
 <div className="btn-gr-border">
              <button disabled={coupanApplied ? true : false} type="button" onClick={handleApplyCoupan} class="btn btn-full">Apply</button>
            </div>
 </div>
</div>



<div className="row btn-row" style={{justifyContent: 'space-between'}}>
<div className="btn-gr-border">
              <button type="button" onClick={() => setStep(step => step - 1)} class="btn">Go Back</button>
            </div>
            <button onClick={onNext} type="button" class="btn btn-gradient btn-border">Proceed to Pay</button>
</div>
<p className='text' style={{marginTop: '20px', color: '#f95e5e'}}><i>We collect an advance amount to book the slot. Remaining bill will be collected at the time of check-out</i></p>
             </div>
            
            </div>
            </div>
        </section>
        </div>
    )
}

export default Review;