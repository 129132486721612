

const Refund = () => {
    return (
        <div>
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Refund Policy</h1>
                   </div>
                </div>
            </section>
  
           <section>
            <div className="my-container" style={{padding: '60px 0'}}>
                <p data-list='1' className="text term-list">
                We collect 50% of the total amount as an advance for
reservation of a slot of our theatre.
                </p>

                <p data-list='2' className="text term-list">
                Cancellation through online only - Go to My Booking
sections and validate your mobile number with OTP and you
can cancel your booking.

                </p>

                <p data-list='3' className="text term-list">
                This advance amount is fully refundable (except convenience
charges of payment gateway, if any) if you cancel the slot 72 hours prior to your booking. Please note that we do not offer refunds for any cancellations
made after the 72-hour window. We also do not offer refunds for no-shows or late arrivals.

                </p> 
                <p data-list='4' className="text term-list">
                Refund is usually initiated within 24 hours and takes
maximum 5-7 days to be completed.
                </p>  
            </div>
           </section>

       </div>
    )
}

export default Refund;