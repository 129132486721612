
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { getSelectedPlan, getSlotsData, getCityByID } from '../../http';

import { ToastContainer, toast } from 'react-toastify';
import BookingRequest from '../BookingRequest/BookingRequest';

const Slot = ({
    step,
    setStep,
    date,
    setDate,
    plan,
    numberOfPerson,
    setNumberOfPerson,
    slot, 
    setSlot,
    city,
    amount,
    setAmount
}) => {

    const [selectedPlan, setSelectedPlan] = useState({features: []})
    const [calculatedPrice, setCalculatedPrice] = useState();
    const [slots, setSlots] = useState([])
    const [cityData, setCityData] = useState({});

    useEffect(() => {
        const fetchSelectedPlan = async () => {
            try {
                const {data} = await getSelectedPlan(plan)
                setSelectedPlan(data.plan)
                setCalculatedPrice(data.plan.price);
                
            } catch (error) {
                console.log(error)
            }
        }
        fetchSelectedPlan()
    }, [plan])

    useEffect(() => {
        const fetchCity = async () => {
            try {
                const {data} = await getCityByID(city);
                setCityData(data.city)
            } catch (error) {
                console.log(error)
            }
        }
        fetchCity()
    }, [city])

    useEffect(() => {
        const fetchSlot = async () => {
            try {
                const {data} = await getSlotsData(date, city, plan)
                console.log(data)
                setSlots(data.slots)
            } catch (error) {
                console.log(error)
            }
        }
        fetchSlot();
    }, [date, plan, city])

    useEffect(() => {

        if(numberOfPerson > selectedPlan.max_Person){
            let priceToInc = (numberOfPerson - selectedPlan.max_Person) * selectedPlan.increment_per_person;
            setCalculatedPrice(priceToInc + selectedPlan.price)
        }else{
            setCalculatedPrice(selectedPlan.price)
        }
        
    }, [numberOfPerson, selectedPlan])

    const onNext = () => {

        if(!date){

            toast.error('Date is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;

        }
        if(!numberOfPerson){
            toast.error('Number of person is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return
        }
        if(!slot){
            toast.error('Slot is required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            return;
        }
        setAmount(calculatedPrice);
        setStep(step => step + 1)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
      },[])
      const [showModel, setShowModel] = useState(false)


    return (
        <div>
            
        <section id="slot" className="step-p-section">
            <div className="my-container">
            <div className="row">
                        <div className='col'>
                        <div className="widget">
                               <FormControl fullWidth>
                               
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker minDate={(dayjs())} defaultValue={dayjs(date)} onChange={(newdate) => setDate(dayjs(newdate).format('YYYY-MM-DD'))} label="Basic date picker" />
                            </LocalizationProvider>
                               </FormControl>

                                <FormControl fullWidth style={{margin: '20px 0'}}>
                                    <InputLabel id="demo-simple-select-label">Number of Person</InputLabel>
                                    <Select
                                        value={numberOfPerson}
                                        onChange={(e) => setNumberOfPerson(e.target.value)}

                                    >
                                        {
                                            Array.from({length: selectedPlan.total_allowed_person}, (_, index) => <MenuItem value={index + 1}>{index + 1}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Slots</InputLabel>
                                    <Select
                                        value={slot}
                                        label="Age"
                                        onChange={(e) => setSlot(e.target.value)}

                                    >
                                        {slots.map((slot) =>  <MenuItem disabled={slot.expired} value={JSON.stringify(slot)}>{`${slot.from} - ${slot.to}`}</MenuItem>)}
                                    </Select>
                                </FormControl>
                        </div>

                        </div>
                   <div className='col'>
                   <div className='widget widget-2'>
                            <h1 className='hero-heading' style={{color: '#c027f4'}}>₹: {calculatedPrice}</h1>
                            <p className='text' style={{color: '#c027f4', marginTop: '30px'}}>Inlusive of all taxes.</p>
                        </div>

                        <div className="row btn-row" style={{justifyContent: 'space-between'}}>
            <div className="btn-gr-border">
                              <button type="button" class="btn" onClick={() => setStep(step => step - 1)}>Go Back</button>
                            </div>
                            <button type="button" class="btn btn-gradient btn-border" onClick={onNext}>Next</button>
            </div>
                   </div>
                    </div>
                    
            </div>
        </section>
        <section id="addon-hero">
                <div className="my-container">
                   <div className="row">
                   <div className="col">
                        <h1 className="hero-heading">{selectedPlan.title}</h1>
                        <span className="accent-heading location-pi"><i class="fa-solid fa-location-dot"></i><span style={{marginLeft: '8px'}}>{cityData.title}</span></span>
                        <ul className="verticle-list">
                            {
                                selectedPlan.features.map((fea) =>   <li><span className="text light">{fea.text}</span></li>)
                            }
                        </ul>
                        <div className="btn-gr-border">
                              <button type="button" onClick={() => setShowModel(true)} class="btn">Book On Call</button>
                            </div>
                    </div>
                    <div className="col addon-r-col">
                        <img className="addon-hero-img" src={selectedPlan.image} />
                    </div>
                   </div>
                </div>
            </section>
        {showModel && <BookingRequest setShowModel={setShowModel} />}
        </div>
    )
}

export default Slot;