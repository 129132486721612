import { AuthContext } from '../context/AuthContext';
import { useContext } from 'react';
import Login from './login/Login';
import { useState } from 'react';
import { getBookingByUser } from '../http';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { cancelBooking } from '../http';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

const MyBooking = () => {

    const auth = useContext(AuthContext);
    const [showLogin, setShowLogin] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [reload, setReload] = useState(false)

    const getSlot = (slots, slot) => {
        return slots.find((s) => s._id == slot)
    }

    useEffect(() => {
        const fetchBooking = async () => {
            try {
                const {data} = await getBookingByUser();
                setBookings(data.booking)
                console.log(data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchBooking();
    }, [reload])

    const onCancel = async (id) => {

       if(window.confirm('Are you sure to cancel this booking')){
        try {
            const {data} = await toast.promise(
                cancelBooking(id),
                {
                  pending: 'Cancelling Booking',
                  success: 'Booking Cancle',
                }
            )
            setReload(!reload)
            
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }
       }
    }

    return (
        <div>
             <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                   <h1 className="hero-heading">My Booking</h1>
                   </div>
                </div>
            </section>

            <section id="my-booking" style={{padding: '60px 0'}}>
                {auth._id ? (
                    
                    bookings.map((book) => (
                        <div className="my-booking-row my-container">
                    <div className="card my-booking-card">
                        <div className="my-booking-inner">
                            <div className="my-booking-left">
                                <img src={book.plan.image}></img>
                            </div>
                            <div className="my-booking-mid">
                                <h1 className="sub-heading color-primary">{book.plan ? book.plan.title : ''} ({book.city ? book.city.title : ''})</h1>
                                <ul className="my-booking-addon-list">
                                    {book.cake && (<li>
                                        <strong className="text" style={{fontWeight: '700'}}>Cake</strong>
                                        <span className="text">{book.cake ? book.cake.title : ''}</span>
                                    </li>)}
                                    {book.decoration && (<li>
                                        <strong className="text" style={{fontWeight: '700'}}>Decoration</strong>
                                        <span className="text">{book.decoration ? book.decoration.title : ''}</span>
                                    </li>)}
                                    <li>
                                        <strong className="text" style={{fontWeight: '700'}}>Date</strong>
                                        <span className="text">{dayjs(book.date).format('D MMMM, YYYY')}</span>
                                    </li>
                                    <li>
                                        <strong className="text" style={{fontWeight: '700'}}>Slot</strong>
                                        <span className="text">{getSlot(book.plan.slots, book.slot).from} - {getSlot(book.plan.slots, book.slot).to}</span>
                                    </li>
                                    <li>
                                        <strong className="text" style={{fontWeight: '700'}}>Person</strong>
                                        <span className="text">{book.number_of_person}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="my-booking-right">
                                <h2 className="accent-heading">Payments Detail</h2>
                            <ul className="my-booking-addon-list">
                                    <li>
                                        <strong className="text" style={{fontWeight: '700'}}>Total:</strong>
                                        <span className="text">₹: {book.total_amount}</span>
                                    </li>
                                    {book.discount > 0 && <li>
                                        <strong className="text" style={{fontWeight: '700'}}>Discount:</strong>
                                        <span className="text">Coupan: {book.coupan_code}, ₹: {book.discount}</span>
                                    </li>}
                                    <li>
                                        <strong className="text" style={{fontWeight: '700'}}>Paid :</strong>
                                        <span className="text">₹: {book.paid_amount}</span>
                                    </li>
                                    <li>
                                        <strong className="text" style={{fontWeight: '700'}}>Dues :</strong>
                                        <span className="text">₹: {book.dues_amount}</span>
                                    </li>
                                </ul>
                                {book.cancled ? <p className='accent-heading' style={{margin: '20px 0 0 0', color: 'red'}}>Booking Cancelled</p> : null}
                                {!book.cancled && Date.parse(book.date) >= Date.now() ? <p className='accent-heading' style={{margin: '20px 0 0 0', color: 'red'}}><button onClick={() => onCancel(book._id)} style={{marginTop:'20px'}} type="button" class="btn btn-gradient btn-border"><i class="fa-solid fa-times"></i><span style={{marginLeft: '8px'}}>Cancel</span></button></p> : null}
                                {!book.cancled && Date.parse(book.date) < Date.now() ? <p className='accent-heading' style={{margin: '20px 0 0 0', color: 'green'}}><button style={{marginTop:'20px'}} type="button" class="btn btn-gradient btn-border"><i class="fa-solid fa-times"></i><span style={{marginLeft: '8px'}}>Completed</span></button></p> : null}
                                
                            </div>
                        </div>
                    </div>
                </div>
                    ))
                    
                ) : <div style={{width: '100%', minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><button onClick={() => setShowLogin(true)} className='btn btn-gradient'>Login</button></div>}
            </section>
            {(showLogin && !auth._id) && <Login reload={reload} setReload={setReload} setShowLogin={setShowLogin} />}
        </div>
    )
}

export default MyBooking;