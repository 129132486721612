import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const Verify = ({otp, setOtp, verifyOtp}) => {
    return (
        <div>
            <FormControl fullWidth style={{margin: '15px 0'}}>
                <TextField value={otp} onChange={(e) => setOtp(e.target.value)} label="Otp" variant="outlined" />
            </FormControl>
            <p style={{marginBottom: '20px', opacity: '.5'}} className='text'>OTP will expire in 5 minutes.</p>
            <button onClick={verifyOtp} className='btn btn-full btn-gradient'>Login</button>
        </div>
    )
}

export default Verify;