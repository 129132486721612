import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Footer = () => {

    const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  },[pathname])

    return (
        <section id="footer">
            <div className="my-container footer-top">
                <div class="row">
                    <div class="footer-col">
                        <img alt="logo" src="/assets/img/logo.png" style={{width: '180px'}} />
                        
                        {/* <center>  <p style={{ color: 'white' }}>
                            <i class="fa-brands fa-facebook" style={{ marginRight: '10px' }}></i>
                            <i class="fa-brands fa-instagram" style={{ marginRight: '10px' }}></i>
                            <i class="fa-brands fa-twitter" style={{ marginRight: '10px' }}></i>
                        </p></center> */}
                    </div>
                    <div class="footer-col" style={{paddingLeft: '40px'}}>
                        <h4 class="widget-title">Quick Links</h4>
                        <ul className="footer-nav">
                            <li>
                                <NavLink to={'/'}><a className="footer-link">Home</a></NavLink>
                            </li>
                            <li>
                                <NavLink to={'/about-us'}><a className="footer-link">About</a></NavLink>
                            </li>
                            <li>
                                <NavLink to={'/gallery'}><a className="footer-link">Gallery</a></NavLink>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-col">
                        <h4 class="widget-title">Important Links</h4>
                    
                            
                                <ul className="footer-nav">
                                    <li>
                                        <NavLink to={'/privacy-policy'}><a href='#' className="footer-link">Privacy Policy</a></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/refund-policy'}><a href='#' className="footer-link">Refund Policy</a></NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/terms-and-conditions'}><a href='#' className="footer-link">Terms & Conditions</a></NavLink>
                                    </li> 
                                </ul>
                          
                       
                    </div>
                    <div class="footer-col" style={{paddingRight: '40px'}}>
                        <h4 class="widget-title">Contact us</h4>
                        
                            <ul className="footer-nav">
                                <li>
                                    <a href='tel:+919502190061' className="footer-link footer-contact">
                                        <i class="fa-solid fa-phone"></i> {/* Adjust padding here */}
                                        <span>+91 95021 90061</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='mailto:bingefiesta@gmail.com' className="footer-link footer-contact">
                                        <i class="fa-solid fa-envelope"></i> {/* Adjust padding here */}
                                        <span>bingenfiesta@gmail.com</span>
                                    </a>
                                </li>
                                <li>
                                    <a href='#' className="footer-link footer-contact">
                                        <i class="fa-solid fa-location-dot"></i> {/* Adjust padding here */}
                                        <span>4th Floor,HDFC Bank Building, My Home Vihanga Rd, Near Wipro Circle , Financial District, Gachibowli, Hyderabad, Telangana 500032</span>
                                    </a>
                                </li>
                            </ul>
                            <p style={{ color: 'white' }}>
                            <a href="https://www.facebook.com/share/U3Ves3r7tD6G1SKT/?mibextid=qi2Omg" style={{color: '#fff'}} target="_blank"><i class="fa-brands fa-facebook" style={{ marginRight: '10px' }}></i></a>
                            <a href="https://www.instagram.com/bingenfiesta?igsh=ZDE4czFrdGsxMDNh" style={{color: '#fff'}} target="_blank"><i class="fa-brands fa-instagram" style={{ marginRight: '10px' }}></i></a>
                            {/* <i class="fa-brands fa-twitter" style={{ marginRight: '10px' }}></i> */}
                        </p>
                        
                    </div>
                </div>
            </div>
            <div className="my-container footer-bottom">
                <div className="row">
                        <ul className="copyright-list">
                            <li>
                                <span className="footer-link">Copyright &copy; {new Date().getFullYear()} All Right Reserved By Binge N Fiesta. </span>
                            </li>
                            <li>
                                <span className="footer-link">Developed By <a href="https://www.waytowebs.com" target="_blank" className="footer-link">Waytowebs</a></span>
                            </li>
                        </ul>
                </div>
            </div>
        </section>
    )
}

export default Footer;