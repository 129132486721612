

const Terms = () => {
    return (
       <div>
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Terms & Conditions</h1>
                   </div>
                </div>
            </section>
  
           <section>
            <div className="my-container" style={{padding: '60px 0'}}>
                <p data-list='1' className="text term-list">
                The booking period includes the time needed for setup and check-out.
                Customers are kindly requested to ensure that they and their belongings 
                are fully vacated from the theatre premises by the specified end time.
                </p>

                <p data-list='2' className="text term-list">
                Customers are advised not to alter or modify the setup of any technical
                equipment provided To ensure optimal functionality, we kindly ask customers
                not to make any alterations or modifications to the setup of the projector,
                screen, laptop, or sound system. If any adjustments are required, our dedicated 
                team will handle them for you.
                </p>

                <p data-list='3' className="text term-list">
                Customers are responsible for any damages caused to the theatre premises and will be held liable for the associated repair or replacement costs.
Any damage to technical or non-technical items within the theatre, such as walls, lights, seating, and more, will incur charges that customers are liable to pay

                </p>

                <p data-list='4' className="text term-list">
                Customers are required to arrange their own content source for the viewing experience
Please note that we do not provide access to any OTT accounts for commercial purposes. To enjoy your favourite content, please ensure you have your own OTT account

                </p>

                <p data-list='5' className="text term-list">
                Customers are prohibited from streaming Illegal/restricted content. We do not take any liability for such cases.
Customers are strictly advised not to stream any content that is illegal or prohibited according to Indian laws. Any violation of this condition will absolve us of any liability for resulting consequences, and future bookings will not be permitted for such customers.

                </p>

                <p data-list='6' className="text term-list">
                In case of any technical interruption from our end, appropriate refund will be provided.
In the event of projector, laptop, internet access, or sound system failure, we will reimburse the paid amount, deducting the rent for the duration prior to the interruption. Your satisfaction is our priority, and we strive to ensure a seamless experience.


                </p>

                <p data-list='7' className="text term-list">
                Strictly no consumption of smoke, alcohol and other substances
The consumption of alcoholic beverages, smoking, or any form of illegal substance is strictly prohibited within the theatre premises. Any customers found in violation of this policy will be subject to appropriate action


                </p>

                <p data-list='8' className="text term-list">
                Minors are not allowed to book the theatre
Individuals below 18 years of age cannot book the theatre. Legal guardians can book the theatre and bring their minor ward(s) along with them.
                </p>

                <p data-list='9' className="text term-list">
                A cleaning fee of up to Rs 1000 will be applied if extensive cleaning is required following the customers check-out
Customers will be responsible for a cleaning fee if the services of professional cleaners are required to address the disposal of garbage, leftovers, and other waste after their check-out

                </p>

                <p data-list='10' className="text term-list">
                The booking of the theatre is limited to individuals of legal age, and minors are not eligible to make reservations.
The theatre booking is exclusively available for individuals who are 18 years of age or older; however, legal guardians can make the reservation and accompany their minor ward(s) during the visit.

                </p>

                <p data-list='11' className="text term-list">
                Customers are responsible for their belongings
We kindly advise customers to take full responsibility for the safety and security of their personal belongings. While we prioritize a safe environment, we cannot be held liable for any loss or damage to personal belongings. Please ensure the proper care and supervision of your belongings during your visit.

                </p>
            </div>
           </section>

       </div>
    )
}


export default Terms;