import { useState, useEffect } from "react";
import { getAddons } from "../http";

const Addons = () => {

    const [addons, setAddons] = useState([])

    useEffect(() => {
        const fetchAddons = async () => {
          try {
    
            const {data} = await getAddons();
            setAddons(data.addons)
            
          } catch (error) {
            console.log(error)
          }
        }
        fetchAddons()
      }, [])


    return (
        <div>
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Add-Onns</h1>
                   </div>
                </div>
            </section>

            
      <section id="decoration" className="step-p-section">
           <div className="my-container">
             <center>
             <h1 className="heading-primary">Select Add-on</h1>
             </center>


             {addons.findIndex((add) => add.type == 'Decoration') != -1 ? (<div style={{marginTop: '30px'}}>
               <center><h2 className='sub-heading color-primary'>Decoration</h2></center>
             <div className="addon-box">
                             
                             {
                              addons.map((add) => add.type == "Decoration" ? (
                                <div className="addon-card" key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             
                            </div>
             </div> ) : null }

             {addons.findIndex((add) => add.type == 'Rose') != -1 ? (<div style={{marginTop: '30px'}}>
             <center><h2 className='sub-heading color-primary'>Rose</h2></center>
             <div className="addon-box" style={{marginTop: '0'}}>
                              
                              
             {
                              addons.map((add) => add.type == "Rose" ? (
                                <div className="addon-card" key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             
                            </div>
             </div>) : null}

             {addons.findIndex((add) => add.type == 'Cake') != -1 ? (<div style={{marginTop: '30px'}}>
             <center><h2 className='sub-heading color-primary'>Cake</h2></center>
             <div className="addon-box" style={{marginTop: '0'}}>
                              
                              
             {
                              addons.map((add) => add.type == "Cake" ? (
                                <div className="addon-card" key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             
                            </div>
             </div>) : null}

             {addons.findIndex((add) => add.type == 'Photography') != -1 ?  (<div style={{marginTop: '30px'}}>
            <center><h2 className='sub-heading color-primary'>Photography</h2></center>
             <div className="addon-box">
             {
                              addons.map((add) => add.type == "Photography" ? (
                                <div className="addon-card" key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             
                            </div>
             </div>) : null }
            
           </div>
        </section>
        </div>
    )
}

export default Addons;