import React, { useState, useEffect, useReducer } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './Layout';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import City from './pages/bookingsteps/City';
import Plans from './pages/bookingsteps/Plans';
import Addon from './pages/bookingsteps/Addon';
import Decoration from './pages/bookingsteps/Decoration';
import Cake from './pages/bookingsteps/Cake';
import Slot from './pages/bookingsteps/Slot';
import Review from './pages/bookingsteps/Review';
import Thankyou from './pages/Thankyou';

import BookNow from './pages/BookNow';

import Terms from './pages/Terms';
import Refund from './pages/Refund';
import Privacy from './pages/Privacy';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Addons from './pages/Addons';
import MyBooking from './pages/MyBooking';

import { autoLogin } from './http';

import { AuthContext, AuthDispatchContext } from './context/AuthContext';
import { authRedicer } from './reducers/authReducer';






const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/booknow',
          element: <BookNow />
        },
        {
          path: '/booknow/success/:tid',
          element : <Thankyou />
        },
        {
          path: '/terms-and-conditions',
          element: <Terms />
        },
        {
          path: '/refund-policy',
          element: <Refund />
        },
        {
          path: '/privacy-policy',
          element: <Privacy />
        },
        {
          path: '/about-us',
          element: <About />
        },
        {
          path: '/gallery',
          element: <Gallery />
        },
        {
          path: '/addons',
          element: <Addons />
        },
        {
          path: '/my-booking',
          element: <MyBooking />
        }
      ]
    }
])


const App = () => {

  const [auth, dispatch] = useReducer(authRedicer, {name: '', email: '', mobile: ''})

  
  useEffect(() => {
    const chechAuth = async () => {
      try {
        const {data} = await autoLogin();
        if(data.auth == false){
          dispatch({type: 'notauthenticated'})
        }else{
          dispatch({type: 'login', user: data.user});
        }
      } catch (error) {
        console.log(error)
        dispatch({type: 'notauthenticated'})
      }
    }
    chechAuth();
  }, [])

  return (
    <>
     <AuthContext.Provider value={auth}>
      <AuthDispatchContext.Provider value={dispatch}>
         <RouterProvider router={router} />
         <ToastContainer style={{zIndex: '9999999999'}} />
      </AuthDispatchContext.Provider>
     </AuthContext.Provider>
   </>
  );
};

export default App;
