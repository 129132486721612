

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { useState } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid/Grid';
import { createBookingRequest, getCityData } from '../../http';
import { validateMobileNumber } from '../../tool/validatoe';
import { toast } from 'react-toastify';
import { useEffect } from 'react';




const BookingRequest = ({setShowModel}) => {

    const [name, setName] = useState('');
    const [date, setDate] = useState(dayjs());
    const [location, setLocation] = useState('');
    const [time, setTime] = useState('');
    const [mobile, setMobile] = useState('');
    const [notes, setNotes] = useState('');
    const [number_of_person, setNumberOfPerson] = useState();

    const [cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const {data} = await getCityData(date);
                setCities(data.cities)
            } catch (error) {
                console.log('cities', error)
            }
        }
        fetchCities();
    }, [date])

    const handleSubmit = async () => {
        if(!name || !date || !number_of_person || !location || !time){
            toast.error("Every fiels are required", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }
       
        if(!validateMobileNumber(mobile)){
            toast.error("Invalid Mobile Number", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }

        try {
            const {data} = await toast.promise(
                createBookingRequest({name, date, location, time, mobile, number_of_person, notes}),
                {
                  pending: 'Submitting...',
                  success: "Submited Successfully",
                }
            )
            setShowModel(false)
            
        } catch (error) {
            setShowModel(false)
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }
        
    }

    return (
        <div className="login-overlay">

        <div className="login-content">
            <button style={{position: 'absolute', top: '30px', right: '30px', padding: '0', background: 'white'}} className="btn" onClick={() => setShowModel(false)}><i className="fa fa-times"></i></button>
            <h1 className="sub-heading" style={{textAlign: 'center'}}>Request A Callback</h1>
            <div style={{marginTop: '30px'}}>
                <div className="row callback-form" style={{flexWrap: 'wrap'}}>
                    <div className="col">
                      <FormControl fullWidth>
                         <TextField defaultValue={name} onChange={(e) => setName(e.target.value)} sx={{color: 'red'}} label="Name" variant="outlined" />
                      </FormControl>
                    </div>
                    <div className='col'>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker minDate={dayjs()} defaultValue={dayjs(date)} onChange={(newdate) => setDate(dayjs(newdate).format('YYYY-MM-DD'))} sx={{color: 'red'}} label="Select Date" />
                           </LocalizationProvider>
                        </FormControl>
                    </div>
                    <div className="col">
                      <FormControl fullWidth>
                         <TextField defaultValue={number_of_person} type='number' onChange={(e) => setNumberOfPerson(e.target.value)} sx={{color: 'red'}} label="Number of Person" variant="outlined" />
                      </FormControl>
                    </div>
                    <div className='col'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Location</InputLabel>
                                <Select
                                    value={location}
                                    label="Location"
                                    onChange={(e) => setLocation(e.target.value)}

                                >
                                   {cities.map((city) =>  <MenuItem value={city.title}>{city.title}</MenuItem>)}
                                </Select>
                        </FormControl>
                    </div>
                    <div className='col'>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Time</InputLabel>
                                <Select
                                    value={time}
                                    label="Time"
                                    onChange={(e) => setTime(e.target.value)}

                                >
                                    <MenuItem value='Morning (09:00 AM to 12:00 PM)'>Morning (09:00 AM to 12:00 PM)</MenuItem>
                                    <MenuItem value='Afternoon (09:00 AM to 12:00 PM)'>Afternoon (12:00 PM to 05:00 PM)</MenuItem>
                                    <MenuItem value='Evening (09:00 AM to 12:00 PM)'>Evening (05:00 PM to 09:00 PM)</MenuItem>
                                    <MenuItem value='Night (09:00 AM to 12:00 PM)'>Night (09:00 PM to 01:00 AM)</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
                    <div className="col">
                      <FormControl fullWidth>
                         <TextField type='number' defaultValue={mobile} onChange={(e) => setMobile(e.target.value)} sx={{color: 'red'}} label="Mobile" variant="outlined" />
                      </FormControl>
                    </div>
                </div>
                <div className="row">
                    <FormControl fullWidth>
                        <TextField defaultValue={notes} onChange={(e) => setNotes(e.target.value)} sx={{color: 'red'}} label="Notes" variant="outlined" />
                    </FormControl>
                </div>
                <button onClick={handleSubmit} style={{marginTop: '20px'}} className='btn btn-full btn-gradient'>Request A Callback</button>
            </div>
        </div>

    </div>
    )
}

export default BookingRequest;