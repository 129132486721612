import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import useBooking from '../../hooks/useBooking';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import BookingRequest from '../BookingRequest/BookingRequest';
import { useState } from 'react';


const City = ({city, setCity, cityData, date, setDate, step, setStep}) => {

    const onNext = (cityId) => {
        setCity(cityId);
        setStep(2);
    }

    const [showModel, setShowModel] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
      },[])

  

    return (
        <div>


        <section id="city">
            <div className="my-container">
                <div className="row city-title">
                    <div className="col">
                        <h1 className="heading-primary">Available Location</h1>
                    </div>
                    <div className="col" style={{display: 'flex', justifyContent: 'end'}}>
                    <FormControl>
                               
                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DatePicker minDate={dayjs()} defaultValue={dayjs(date)} onChange={(newdate) => setDate(dayjs(newdate).format('YYYY-MM-DD'))} sx={{color: 'red'}} label="Select Date" />
                           </LocalizationProvider>
                              </FormControl>
                    </div>
                </div>
                <div className="row city-row">
                    {
                        cityData.cities.map((city) => (
                            <div className="city-col" onClick={() => onNext(city._id)} key={city._id}>
                        <div className="city-box">
                            <div className="city-box-inner">
                                <div className="city-img">
                                    <img src={city.image} />
                                </div>
                                <div className="city-content">
                                    <center>
                                        <h2 className="sub-heading color-primary">{city.title}</h2>
                                        <span className="text" style={{ color: 'green' }}>{cityData.slots[city._id]} Slots available on {dayjs(date).format('D MMMM, YYYY')}</span>
                                        
                                        <button type="button" class="btn btn-gradient btn-border">Book Now</button>
                                       
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                        ))
                    }
                </div>
                <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
                <div className="btn-gr-border">
                <button type="button" onClick={() => setShowModel(true)} class="btn"><i class="fa-solid fa-phone"></i><span style={{marginLeft: '8px'}}>Book On Call / Join Waitlist</span></button>
                </div>
                {/* <div className="btn-gr-border" style={{marginLeft: '15px'}}><button onClick={() => setShowModel(true)} type="button" class="btn">Join Waitlist</button></div> */}
                {/* <div className="btn-gr-border"><button type="button" class="btn">Explore More</button></div>*/}
                </div>
            </div>
        </section>
        {/* <section id="addon-hero">
   
                <div className="my-container">
                   <div className="row">
                   <div className="col">
                        <h1 className="hero-heading">SELECT CITY</h1>
                        <span className="accent-heading location-pi"><i class="fa-solid fa-location-dot"></i><span style={{marginLeft: '8px'}}>available in {cityData.cities.length} city</span></span>
                        <ul className="verticle-list">
                            <li><i style={{color: '#fff', marginRight: '8px'}} className='fa fa-check'></i><span className="text light">₹2799 for 6 or less people (Rs 400 per extra person)</span></li>
                            <li><i style={{color: '#fff', marginRight: '8px'}} className='fa fa-check'></i><span className="text light">Mega 150 inch enhanced 4k Video. Powerful 1000W Dolby atmos sound system.</span></li>
                            <li><i style={{color: '#fff', marginRight: '8px'}} className='fa fa-check'></i><span className="text light">Food & Beverages can be ordered at the theatre.</span></li>
                        </ul>
                        <div className="btn-gr-border">
                              <button onClick={() => setShowModel(true)} type="button" class="btn">Book on call</button>
                            </div>
                    </div>
                    <div className="col addon-r-col">
                        <img className="addon-hero-img" src="/assets/img/def.png" />
                    </div>
                   </div>
                </div>
            </section> */}
        {showModel && <BookingRequest setShowModel={setShowModel} />}
        </div>
    )
}

export default City;