import { useState, useContext } from "react";
import Otp from "./Otp";
import Verify from "./Verify";
import { sentOtpReq, verifyOtpReq } from "../../http";
import { toast } from 'react-toastify';
import { AuthDispatchContext } from "../../context/AuthContext";
import { validateMobileNumber } from "../../tool/validatoe";
import { validateEmail } from "../../tool/validatoe";


const Login = ({setShowLogin, reload, setReload}) => {

    const dispatch = useContext(AuthDispatchContext)

    const [email, setEmail] = useState();
    const [otp, setOtp] = useState();
    const [step, setStep] = useState('otp');
    const [hash, setHash] = useState()
    

    const sendOtp = async () => {

        if(!validateEmail(email)){
            toast.error("Invalid Email ID", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }



        try {
            const {data} = await toast.promise(
                sentOtpReq({email}),
                {
                  pending: 'Sending OTP',
                  success: 'OTP Sent',
                }
            )
            setHash(data.hash)
            setStep('verify')
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }
        
    }
    const verifyOtp = async () => {
        try {
            const {data} = await toast.promise(
                verifyOtpReq({email, otp, hash}),
                {
                  pending: 'Verifying OTP',
                  success: 'Login Success',
                }
            )
            dispatch({type: 'login', user: data.user});
            setReload(!reload)
            
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            return
        }
        
    }



    return <div className="login-overlay">

        <div className="login-content">
            <button style={{position: 'absolute', top: '30px', right: '30px', padding: '0', background: 'white'}} className="btn" onClick={() => setShowLogin(false)}><i className="fa fa-times"></i></button>
            <img alt="logo" src="/assets/img/logo.png" />
            <h1 className="sub-heading" style={{textAlign: 'center'}}>Login</h1>
            <div>
                {step === 'otp' && <Otp email={email} setEmail={setEmail} sendOtp={sendOtp} />}
                {step === 'verify' && <Verify setOtp={setOtp} otp={otp} verifyOtp={verifyOtp} />}
            </div>
        </div>

    </div>
}

export default Login;