import { useState, useEffect } from "react";
import { getDecorations } from "../../http";
import { ToastContainer, toast } from 'react-toastify';

const Decoration = ({
   step,
   setStep,
   decoration,
   setDecoration
}) => {

   const [decorations, setDecorations] = useState([]);

   useEffect(() => {
      window.scrollTo(0, 0)
    },[])

   useEffect(() => {
      const fetchDecoration = async () => {
         try {
            const {data} = await getDecorations();
            setDecorations(data.decorations)
         } catch (error) {
            console.log(error)
         }

      }
      fetchDecoration()
   }, [])

   const onNext = () => {

      if(!decoration._id){
         toast.error('Please select a Decoration', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
        });
        return;
      }
      setStep(step => step + 1)
   }

    return (
      <div>
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Decoration</h1>
                   </div>
                </div>
            </section>

        <section id="decoration" className="step-p-section">
           <div className="my-container">
             <center>
             <h1 className="heading-primary">Select Decoration</h1>
             <p className="text" style={{color: '#f95e5e'}}><i><strong>Note: </strong>Decorations are not customizable, Please select predefined add-ons in the next windows</i></p>
             </center>
             <div className="addon-box">
                              
                              
                              
                           {
                              decorations.map((decor) => (
                                 <div className={`addon-card ${decoration._id == decor._id ? 'selectrd-card' : ''}`} key={decor._id} onClick={() => setDecoration(decor)}>
                                 <div className="addon-card-inner">
                                    <center>
                                    <img src={decor.image} />
                                    <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{decor.title}</p>
                                    <p className="text"><strong>₹: {decor.price}</strong></p>
                                    </center>
                                 </div>
                               </div>
                              ))
                           }
                             
                            </div>
            <div className="row btn-row" style={{justifyContent: 'space-between'}}>
            <div className="btn-gr-border">
                              <button type="button" class="btn" onClick={() => setStep(step => step - 1)}>Go Back</button>
                            </div>
                            <button type="button" class="btn btn-gradient btn-border" onClick={onNext}>Next</button>
            </div>
           </div>
        </section>
        </div>
    )
}

export default Decoration;