import {Outlet} from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import { useRef, useEffect, useState } from 'react';

const Layout = () => {

  const headerRef = useRef(null);
  const pageRef = useRef(null);
  const [headerHeight, setHeaderHeiight] = useState(185);

  useEffect(() => {
    const updatePadding = () => {
      if (headerRef.current && pageRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        pageRef.current.style.paddingTop = `${headerHeight}px`;
        setHeaderHeiight(headerHeight)

      }
    };

    // Initial update
    //updatePadding();

    // Update on window resize to handle dynamic header height changes
    window.addEventListener('resize', updatePadding);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', updatePadding);
    };
  }, [headerRef.current, pageRef.current]);


    return (
       <div id="page" style={{paddingTop: `${headerHeight}px`}} ref={pageRef}>
         <Header headerRef={headerRef} />
         { <Outlet /> }
         <Footer />
       </div>
    )
}


export default Layout;