import { useState, useEffect } from "react";
import { verifyCheckOut } from "../http";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ToastContainer, toast } from 'react-toastify';
import {NavLink} from 'react-router-dom'


const Thankyou = () => {


    const [booking, setBooking] = useState({});
    const [slot, setSlot] = useState({});
    const navigate = useNavigate();

    const {tid} = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const [body, setBody] = useState(searchParams.get('body'));

    useEffect(() => {
        console.log('tid', tid);
        console.log('body', JSON.parse(body))
        const fetchBooking = async () => {
            try {
               const {data} = await verifyCheckOut(tid, JSON.parse(body));
               if(data.success){
                setBooking(data.booking)
                data.booking.plan.slots.forEach((sl) => {
                    if(sl._id == data.booking.slot){
                        setSlot(sl)
                    }
                })
               }else{
                toast.error('Payment fail or pending', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                //navigate('/booknow')
               }

               console.log(data)
             
            } catch (error) {
                console.log(error)
            }
        }
        fetchBooking();
    }, [tid])


        const printDocument = () => {
          const input = document.getElementById('thankyou-body');
          html2canvas(input)
            .then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF('p', 'mm', 'a4');
              const imgWidth = 210;
              const pageHeight = 295;
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
              let heightLeft = imgHeight;
              let position = 0;
      
              pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
      
              while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
              }
              pdf.save('booking.pdf');
            });
        }
    

    return (
        <div>
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Thank You.</h1>
                   </div>
                </div>
            </section>

            <section id="thankyou-body"  style={{width: '90%', maxWidth: '700px', margin: '30px auto', paddingTop: '30px'}}>
                <div className="my-container">
                    <div>
                        <center>
                            <img alt="logo" src="/assets/img/logo.png" style={{width: '150px', marginBottom: '10px'}} />
                            <h1 className="heading-primary">Your booking was successfull</h1>
                        </center>
                    </div>
                    <div className="widget" style={{width: '90%', maxWidth: '700px', margin: '10px auto'}}>
                        <center><h2 className="sub-heading color-primary"><img style={{display: 'inline', width: '30px', marginRight: '8px', verticalAlign: 'middle'}} alt="check" src="/assets/img/check.png" />Booking Details</h2></center>
                        <div className="booking-table" style={{marginTop: '20px'}}>
                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Date</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{dayjs(booking.date).format('D MMMM, YYYY')}</strong></h3>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>City</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.city ? booking.city.title : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Plan</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.plan ? booking.plan.title : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Number of Person</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.number_of_person}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Slot</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{slot.from} - {slot.to}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Decoration</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.decoration ? `${booking.decoration.title} (₹: ${booking.decoration.price})` : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Cake</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.cake ? `${booking.cake.title} (₹: ${booking.cake.price})` : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Addons</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.addons ? booking.addons.map((add) => `${add.title} (₹: ${add.price})`) : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Total Amount</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>₹: {booking.total_amount}</strong></h3>
                                </div>
                            </div>

                            {booking.discount > 0 && <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Discount</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>₹: {booking.discount}</strong></h3>
                                </div>
                            </div>}
                            {booking.discount > 0 && <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Coupan Applied</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.coupan_code}</strong></h3>
                                </div>
                            </div>}
                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Paid Amount</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>₹: {booking.paid_amount}</strong></h3>
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Dues Amount</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>₹: {booking.dues_amount}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Name</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.user ? booking.user.name : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Email</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.user ? booking.user.email : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Mobile</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{booking.user ? booking.user.mobile : ''}</strong></h3>
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="cell cell-l">
                                    <h3 className="accent-heading" style={{marginBottom: '0'}}>Status</h3>
                                </div>
                                <div className="cell">
                                    <h3 className="text"><strong>{!booking.cancled ? <span style={{color: 'green'}}>Active</span> : <span style={{color: 'red'}}>Cancled</span>}</strong></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <center>
                        <p style={{margin: '20px p'}} className="text">For more information visit out <NavLink to={'/terms-and-conditions'}><span style={{color: '#c027f4'}}>Terms & Conditions</span></NavLink> page.</p>
                    </center> 
                </div>
            </section>
           <div style={{display: 'flex', justifyContent: 'center', paddingBottom: '60px'}}>
           <button
            className="btn btn-gradient header-btn"
            type="submit"
            onClick={printDocument}
            style={{margin: '0 auto'}}
          >
           Download
          </button>
           </div>
        </div>
    )
}

export default Thankyou;