import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import useBooking from '../../hooks/useBooking';
import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { getPlanData } from '../../http';
import BookingRequest from '../BookingRequest/BookingRequest';


const Plans = ({city, setCity, cityData, date, setDate, step, setStep, setPlan}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      },[])

    const onNext = (planId) => {
        setPlan(planId);
        setStep(3)
    }

    const [planData, setPlanData] = useState({plans : [], slots : {}});

    useEffect(() => {
        const getPlan = async () => {
            try {
                const {data} = await getPlanData(date, city);
                setPlanData(data)
                console.log(data)
            } catch (error) {
                console.log(error)  
            }
        }
        getPlan()
    }, [date, city])

    const [showModel, setShowModel] = useState(false)



 

    return(
        <div>
           
        <section id="city">
        <div className="my-container">
            <div className="row city-title">
                <div className="col">
                    <h1 className="heading-primary">Available Theatre</h1>
                </div>
                <div className="col" style={{display: 'flex', justifyContent: 'end'}}>
                    <FormControl>
                               
                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DatePicker minDate={dayjs()} defaultValue={dayjs(date)} onChange={(newdate) => setDate(dayjs(newdate).format('YYYY-MM-DD'))} label="Select Date" />
                           </LocalizationProvider>
                              </FormControl>
                    </div>
            </div>
            <div className="row city-row">

                {
                    planData.plans.map((plan) => (
                        <div className="city-col" key={plan._id}>
                    <div className="city-box">
                        <div className="city-box-inner">
                            <div className="city-img">
                                <img src={plan.image} />
                            </div>
                            <div className="city-content">
                                <center>
                                    <h2 className="sub-heading color-primary" style={{marginBottom: '15px'}}>{plan.title}</h2>
                                    <p className="text">₹: {plan.price} for {plan.max_Person} or less people</p>
                                    <p className="text" style={{marginBottom: '15px'}}>(₹: {plan.increment_per_person} per extra person)</p>
                                    <p className="text" style={{ color: 'green' }}>{planData.slots[plan._id] > 0 ? planData.slots[plan._id] : '0'} Slots available on {dayjs(date).format('D MMMM, YYYY')}</p>
                                    <button type="button" class="btn btn-gradient btn-border" style={{margin: '10px 0'}} onClick={() => onNext(plan._id)}>Book Now</button>
                                    {/* <p className="accent-heading" style={{margin: '0'}}>2 - 10 People</p> */}
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
                    ))
                }

                
            </div>
            <div style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
            <div className="btn-gr-border">
            <button type="button" onClick={() => setShowModel(true)} class="btn"><i class="fa-solid fa-phone"></i><span style={{marginLeft: '8px'}}>Book On Call / Join Waitlisted</span></button>
            </div>
                {/* <div className="btn-gr-border" style={{marginLeft: '15px'}}><button onClick={() => setShowModel(true)} type="button" class="btn">Join Waitlist</button></div> */}
            </div>
        </div>
    </section>
    {/* <section id="addon-hero">
                <div className="my-container">
                   <div className="row">
                   <div className="col">
                        <h1 className="hero-heading">SELECT Plan</h1>
                        <span className="accent-heading location-pi"><i class="fa-solid fa-location-dot"></i><span style={{marginLeft: '8px'}}>available 6 Plans</span></span>
                        <ul className="verticle-list">
                            <li><span className="text light">₹2799 for 6 or less people (Rs 400 per extra person)</span></li>
                            <li><span className="text light">Mega 150 inch enhanced 4k Video. Powerful 1000W Dolby atmos sound system (In-wall speakers). Ideal for family and friends.</span></li>
                            <li><span className="text light">Food & Beverages can be ordered at the theatre.</span></li>
                        </ul>
                        <div className="btn-gr-border">
                              <button type="button" onClick={() => setShowModel(true)} class="btn">Book on call</button>
                            </div>
                    </div>
                    <div className="col addon-r-col">
                        <img className="addon-hero-img" src="/assets/img/def.png" />
                    </div>
                   </div>
                </div>
            </section> */}
    {showModel && <BookingRequest setShowModel={setShowModel} />}
    </div>
    )
}

export default Plans;