import { useState, useEffect } from "react";
import { getGallery } from "../http";
import Slider from "react-slick";

const Gallery = () => {

    const [gallery, setGallery] = useState([]);
    const [bigGallery, setBigGallery] = useState(false)
    const [act, setAct] = useState();

    useEffect(() => {
        const fetchGallery = async () => {
            try {
                const {data} = await getGallery();
                setGallery(data.gallery)
            } catch (error) {
                console.log(error)
            }
        }
        fetchGallery();
    }, [])


    var settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        auto: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        spacing: 0,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const handleGal = (act) => {
       setAct(act)
       setBigGallery(true)
    }


    return (
        <div>
            <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Our Gallery</h1>
                   </div>
                </div>
            </section>

            <section id="gallery" style={{padding: '60px 0'}}>
                <div className="my-container">
                    <div className="gallery-grid">
                       
                       {
                        gallery.map((gl) => (
                            <div className="gallery-item">
                                <img onClick={() => handleGal(gl.image)} alt="thumbnail" src={gl.image} />
                            </div>
                        ))
                       }

                       {
                        bigGallery ? (
                            <div className="gal-overlay" >
                                <div className="gallery-box">
                                <button style={{padding: '0', background: 'transparent', color: 'white', marginBottom: '30px', textAlign: 'right', display: 'inline'}} className="btn" onClick={() => setBigGallery(false)}><i className="fa fa-times"></i></button>
                                    <div>
                                        <Slider {...settings}>
                                                <div className="gallery-item">
                                                    <img alt="thumbnail" src={act} />
                                                </div>
                                            {
                                                gallery.map((gl) => (
                                                    <div className="gallery-item">
                                                        <img alt="thumbnail" src={gl.image} />
                                                    </div>
                                                ))
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        ) : null
                       }
                        
                
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Gallery;