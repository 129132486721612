

const About = () => {
    return (
        <div>
             <section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">About US</h1>
                   </div>
                </div>
            </section>
            <section id="about">
                <div className="my-container">
                    <div class="row">
                        <div className="col abt-col-left">
                        <img style={{borderRadius: '8px'}} src={'./assets/img/bingnfiest-img.png'} alt="" />
                        </div>
                        <div className="col col-vertical-center abt-col-right">
                            <div>
                            <span className="accent-heading">About US</span>
                            <h1 className="heading-primary">Best Private Theaters <br />in  <span className="gradient-text">Hyderabad</span> </h1>
                            <p className="text">Welcome to Binge N Fiesta. We believe that every moment is worth celebrating. Our luxurious private home theater is designed to transport you into a world of cinematic magic, where you can create unforgettable memories with your loved ones. Whether it's a birthday, anniversary or any special occasions, we have got you covered.</p>
                            <div className="btn-gr-border">
                              {/* <button type="button" class="btn">Explore More</button> */}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About;