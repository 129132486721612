import CategoryCard from "../components/CategoryCard";
import Typewriter from "typewriter-effect";
import { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import AddonCard from "../components/AddonCard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import { getAddons, getAppSeting } from "../http";


const Home = () => {

    const typingRef = useRef(null)

    const [addons, setAddons] = useState([]);
    const [app, setApp] = useState({})

    useEffect(() => {
        const fetchAddons = async () => {
          try {
    
            const {data} = await getAddons();
            const res = await getAppSeting();
            setApp(res.data.setting)
            setAddons(data.addons)
            
          } catch (error) {
            console.log(error)
          }
        }
        fetchAddons()
      }, [])


    var settings = {
        dots: false,
        infinite: true,
        speed: 3000,
        auto: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed:2000,
        spacing: 40,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

  

  


    return (
        <div>


      {/* Hero Section */}
         <section id="hero-section" style={{backgroundImage: `url(${app.banner_image}), linear-gradient(90deg, #33193a, #d4ced5)`}}>
           
           <div className="my-container">
                <div className="row">
                    <div className="hero-content">
                        <div className="content" >
                            <div className="hero-h-box" style={{minHeight: '220px'}}>
                            <Slider {...settings} slidesToShow={1} arrows={false} autoplaySpeed={1000} speed={2000}>
                              <h1 className="hero-heading">Your Event Starts With Us</h1>
                              <h1 className="hero-heading">Every event is special but yours should be unique</h1>
                              <h1 className="hero-heading">Enjoy the view with your special ones on the big screen with Binge N Fiesta</h1>
                            </Slider>
                            {/* <h1 ref={typingRef.current} className="hero-heading">
                            <Typewriter options={{loop: true}}
                onInit={(typewriter) => {
                    typewriter
                        .typeString("Your Event Starts With Us")
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString("Every event is special but yours should be unique")
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString('Enjoy the view with your special ones on the big screen with Binge N Fiesta')
                        .pauseFor(1000)
                        .deleteAll()
                        .start()
                }}
            />
                            </h1> */}
                            </div>
                            <p className="text light" style={{fontSize: '20px'}}>
                            Celebrate your event with a Dolby Atmos and extended 4K video system can elevate the experience to new heights 
                            </p>
                            <NavLink to={'/booknow'}><button type="button" class="btn btn-gradient btn-border">Book Now</button></NavLink>
                        </div>
                    </div>
                </div>

            </div>
        </section>


        {/* section two */}


        <section id="cat">
        <div className="my-container">
      <div id="carouselId" className="carousel slide" data-ride="carousel" style={{ marginTop: '5%' }}>
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item">
            <div className="card-deck">
             <Slider {...settings}>
             <CategoryCard
                title="Birthday Party"
                text="Celebrate your birthday in style with our private theatre experience. Enjoy watching your favorite movies."
                image={'/assets/img/bday.png'}
              />
               <CategoryCard
                title="Anniversaries"
                text="Mark your milestone anniversary with a romantic and intimate private theatre celebration."
                image={'/assets/img/pic22.png'}
              />
               <CategoryCard
                title="Farewell Parties"
                text="Bid farewell in an unforgettable way with our event services. Celebrate cherished memories."
                image={'/assets/img/fwell.png'}
              />
               <CategoryCard
                title="Bride to Be"
                text="Make her feel special with a celebration to remember. Our tailored experiences are perfect."
                image={'/assets/img/pic22.png'}
              />
               <CategoryCard
                title="MOM to Be"
                text="Celebrate the upcoming arrival with a beautiful and intimate gathering. Our services cater to every need."
                image={'/assets/img/pic22.png'}
              />
              <CategoryCard
                title="Love Proposal"
                text=" Pop the question in a setting as special as your love. Our proposal experiences create a magic."
                image={'/assets/img/pic22.png'}
              />
              <CategoryCard
                title="Events"
                text="Whether it's a reunion, farewell, or any other social gathering, our private theatres provide a unique venue"
                image={'/assets/img/pic22.png'}
              />
             </Slider>
             
            </div>
          </div>
         
        </div>
      </div>
    </div>
        </section>


        {/* Section  Three */}
            <section id="about">
                <div className="my-container">
                    <div class="row">
                        <div className="col abt-col-left">
                            <img style={{borderRadius: '8px'}} src={'./assets/img/bingnfiest-img.png'} alt="" />
                        </div>
                        <div className="col col-vertical-center abt-col-right">
                            <div>
                            <span className="accent-heading">About US</span>
                            <h1 className="heading-primary">Best Private Theaters <br />in  <span className="gradient-text">Hyderabad</span> </h1>
                            <p className="text">Welcome to Binge N Fiesta. We believe that every moment is worth celebrating. Our luxurious private home theater is designed to transport you into a world of cinematic magic, where you can create unforgettable memories with your loved ones. Whether it's a birthday, anniversary or any special occasions, we have got you covered.</p>
                            {/* <div className="btn-gr-border">
                              <NavLink to='/about-us'><button type="button" class="btn">Explore More</button></NavLink>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        {/* Section Four */}
            <section id="how-work">
                <div className="my-container">
                    <center> <h1 class="heading-primary">How It Works</h1></center>
                    <div className="row" style={{marginTop:'170px'}}>
                        <div className="col how-card theater" style={{ width: 'calc(33.33% - 40px)' }}>
                            <div className="card-body">
                                <center>
                                <h2 className="sub-heading color-primary">Booking The Theatre</h2>
                                <p className="text">You can check available slots and book the theatre from this website. For more details you can contact us on +91 95021 90061 (WhatsApp Only)</p>
                                <NavLink to={'/booknow'}><button type="button" class="btn btn-gradient btn-border" style={{marginTop: '20px'}}>Book Now</button></NavLink>
                                </center>
                            
                            </div>
                        </div>

                        <div className="col how-card decoration" style={{ width: 'calc(33.33% - 40px)' }}>
                            <div className="card-body">
                                <center>
                                <h2 className="sub-heading color-primary">Special Decorations</h2>
                                <p className="text">Want to make your loved ones feel special? Get the private theatre beautifully decorated at just Rs 399. Decoration can be added while booking.</p>
                                <NavLink to={'/booknow'}><button type="button" class="btn btn-gradient btn-border" style={{marginTop: '20px'}}>Book Now</button></NavLink>
                                </center>
                            </div>
                        </div>

                        <div className="col how-card ott" style={{ width: 'calc(33.33% - 40px)' }}>
                            <div className="card-body">
                                <center>
                                <h2 className="sub-heading color-primary">What Can You Watch?</h2>
                                <p className="text">We do NOT provide any content. You can use any of your OTT account or downloads to watch your favorite content. We will do all the setup for you</p>
                                <NavLink to={'/booknow'}><button type="button" class="btn btn-gradient btn-border" style={{marginTop: '20px'}}>Book Now</button></NavLink>
                                </center>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>




       {/* Section Five */}
            <section id="addon">
                <div className="my-container">
                    <center><h1 class="heading-primary">Picks <span className="gradient-text">
                        Add-Ons
                    </span> </h1></center>

                    <div style={{marginTop: '30px'}}>
                        <Slider {...settings} >
                            {
                                addons.map((addon) => <AddonCard addon={addon} />)
                            }
                        </Slider>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Home;