const CategoryCard = ({ title, text, image }) => {
    return (
      <div className="cat-card-box">
      <div className="card gradient-card">
           <div>
           <center>
          <img className="card-img-top" style={{ width: '40%' }} src={image} alt="" />
        </center>
        <div className="card-body">
          <center>
            <h4 className="sub-heading color-primary">{title}</h4>
            <p className="text">{text}</p>
          </center>
        </div>
           </div>
      </div>
      </div>
    );
};

export default CategoryCard;