
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useState, useEffect } from 'react';
import { getAddons } from '../../http';



const Addon = ({step, setStep, addon, setAddon}) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  },[])

  const [addons, setAddons] = useState([]);

  useEffect(() => {
    const fetchAddons = async () => {
      try {

        const {data} = await getAddons();
        setAddons(data.addons)
        
      } catch (error) {
        console.log(error)
      }
    }
    fetchAddons()
  }, [])

  const handleAddon = (add) => {
    console.log(addon);
    let pre = [...addon];

    if (pre.length === 0) {
        setAddon([add]);
        return;
    }

    // Check if the item is already in the array
    const exists = pre.some(ad => ad._id === add._id);

    if (exists) {
        // If the item exists, remove it
        setAddon(pre.filter(p => p._id !== add._id));
    } else {
        // If the item does not exist, add it
        pre.push(add);
        setAddon(pre);
    }
};



  const onNext = () => {
    setStep(step => step + 1);
  }

    return (
      <div>

<section id="addon-hero">
                <div className="my-container">
                   <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                 
                   <h1 className="hero-heading">Add-Ons</h1>
                   </div>
                </div>
            </section>


      <section id="decoration" className="step-p-section">
           <div className="my-container">
             <center>
             <h1 className="heading-primary">Select Add-on</h1>
             </center>


             {addons.findIndex((add) => add.type == 'Decoration') != -1 ? (<div style={{marginTop: '30px'}}>
               <center><h2 className='sub-heading color-primary'>Decoration</h2></center>
             <div className="addon-box">
                             
                             {
                              addons.map((add) => add.type == "Decoration" ? (
                                <div onClick={() => handleAddon(add)} className={`addon-card ${addon.find((item) => item._id == add._id) ? 'selectrd-card' : ''}`} key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             
                            </div>
             </div>) : null}

             {addons.findIndex((add) => add.type == 'Rose') != -1 ? (<div style={{marginTop: '30px'}}>
               <center><h2 className='sub-heading color-primary'>Rose</h2></center>
             <div className="addon-box" style={{marginTop: '0'}}>
                              
                              
             {
                              addons.map((add) => add.type == "Rose" ? (
                                <div onClick={() => handleAddon(add)} className={`addon-card ${addon.find((item) => item._id == add._id) ? 'selectrd-card' : ''}`} key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             
                            </div>
             </div>) : null }

             {addons.findIndex((add) => add.type == 'Cake') != -1 ? (<div style={{marginTop: '30px'}}>
               <center><h2 className='sub-heading color-primary'>Cake</h2></center>
             <div className="addon-box" style={{marginTop: '0'}}>
                              
                              
             {
                              addons.map((add) => add.type == "Cake" ? (
                                <div onClick={() => handleAddon(add)} className={`addon-card ${addon.find((item) => item._id == add._id) ? 'selectrd-card' : ''}`} key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             
                            </div>
             </div>) : null }

             {addons.findIndex((add) => add.type == 'Photography') != -1 ?  (<div style={{marginTop: '30px'}}>
               <center><h2 className='sub-heading color-primary'>Phhotography</h2></center>
             <div className="addon-box">
             {
                              addons.map((add) => add.type == "Photography" ? (
                                <div onClick={() => handleAddon(add)} className={`addon-card ${addon.find((item) => item._id == add._id) ? 'selectrd-card' : ''}`} key={add._id}>
                                <div className="addon-card-inner">
                                   <center>
                                   <img src={add.image} />
                                   <p className="accent-heading" style={{marginTop:'20px', marginBottom:'10px'}}>{add.title}</p>
                                   <p className="text"><strong>₹: {add.price}</strong></p>
                                   </center>
                                </div>
                              </div>
                              ) : null )
                             }
                             <div>
                              <p style={{color: '#f95e5e'}} className='text'><strong>Note :</strong></p>
                              <ul style={{flexDirection: 'column'}}>
                                <li style={{color: '#f95e5e'}} className='text'><i>1. For photoclippings, our team will reach out to you on the day of booking. You need to send soft copies of the photos you want to place inside the theater.
                                </i ></li>
                                <li style={{color: '#f95e5e'}} className='text'><i>2. You can take those hard copies with you after the party!</i>
                                </li>
                                <li style={{color: '#f95e5e'}} className='text'><i>3. Timing of the photography is according to the availability of the photographer.</i></li>
                              </ul>
                             </div>
                            </div>
             </div>) : null}


            <div className="row btn-row" style={{justifyContent: 'space-between'}}>
            <div className="btn-gr-border">
                              <button onClick={() => setStep(step => step - 1)} type="button" class="btn">Go Back</button>
                            </div>
                            <button onClick={onNext} type="button" class="btn btn-gradient btn-border">Next</button>
            </div>
           </div>
        </section>
        </div>
    )
}

export default Addon;