import { NavLink } from "react-router-dom"

const AddonCard = ({addon}) => {
    return (
        <div className="addon-box">
            <div class="gradient-card2">
            <div class="card-body">
                <img class="card-img-top" src={addon.image} alt="addon-thum" style={{width: '100%', height: '200px', objectFit: 'cover'}} />
                <h2 class="sub-heading color-primary">{addon.title}</h2>
                <p className="text">{addon.description}</p>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px', alignItems: 'flex-end'}}>
                    <p className="accent-heading" style={{fontWeight: '900', fontSize: '20px', color: '#000'}}>₹: {addon.price}</p>
                    {/* <div className="btn-gr-border">
                        <NavLink to={'/booknow'}><button type="button" class="btn">Book Now</button></NavLink>
                    </div> */}
                </div>
            </div>
        </div>
        </div>
    )
}

export default AddonCard