import { useState, useEffect, useContext } from "react";
import { getCityData, getPlanData } from "../http";
import { AuthContext } from "../context/AuthContext";
import dayjs from "dayjs";


const useBooking = () => {

    const auth = useContext(AuthContext)

    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [city, setCity] = useState('');
    const [plan, setPlan] = useState('');
    const [slot, setSlot] = useState(null);
    const [numberOfPerson, setNumberOfPerson] = useState(null);
    const [decoration, setDecoration] = useState({price: 0});
    const [cake, setCake] = useState({price: 0, eggless_price: 0});
    const [addon, setAddon] = useState([]);
    const [name, setName] = useState(auth.name);
    const [mobile, setMobile] = useState(auth.mobile);
    const [email, setEmail] = useState(auth.email);
    const [amount, setAmount] = useState();
    const [eggless, setEggless] = useState(false)


    //data from server
    const [cityData, setCityData] = useState({cities : [], slots : {}})
    const [planData, setPlanData] = useState({plans : [], slots : {}});
    const [slotsData, setSlotsData] = useState({slots: []});

    useEffect(() => {
        const getCitt = async () => {
            try {
                const {data} = await getCityData(date);
                setCityData(data)
            } catch (error) {
                console.log(error)  
            }
        }
        getCitt()
    }, [date])

    // useEffect(() => {
    //     const getPlan = async () => {
    //         try {
    //             const {data} = await getPlanData(date, city);
    //             setPlanData(data)
    //         } catch (error) {
    //             console.log(error)  
    //         }
    //     }
    //     getPlan()
    // }, [date, city])



    return {
        cityData,
        planData,
        slotsData,
        date,
        setDate,
        city,
        setCity,
        setPlan,
        numberOfPerson,
        setNumberOfPerson,
        slot, 
        setSlot,
        plan,
        amount,
        setAmount,
        decoration,
        setDecoration,
        cake,
        setCake,
        addon,
        setAddon,
        name,
        setName,
        email,
        setEmail,
        mobile,
        setMobile,
        eggless,
        setEggless
    }
}

export default useBooking;