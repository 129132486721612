import City from "./bookingsteps/City";
import Plans from "./bookingsteps/Plans";
import Slot from './bookingsteps/Slot';
import Decorations from "./bookingsteps/Decoration";
import Cakes from "./bookingsteps/Cake";
import Review from './bookingsteps/Review';
import Addon from './bookingsteps/Addon'

import { useState } from "react";
import useBooking from "../hooks/useBooking";

const BookNow = () =>  {

    const [step, setStep] = useState(1);

    const {
        date,
        setDate,
        cityData,
        city,
        setCity,
        planData,
        setPlan,
        slot, 
        setSlot,
        numberOfPerson,
        setNumberOfPerson,
        plan,
        amount,
        setAmount,
        decoration,
        setDecoration,
        cake,
        setCake,
        addon,
        setAddon,
        name,
        setName,
        email,
        setEmail,
        mobile,
        setMobile,
        eggless,
        setEggless

    } = useBooking();

    const stepMap = {
        1: <City setStep={setStep} step={step} city={city} setCity={setCity} cityData={cityData} date={date} setDate={setDate} />,
        2: <Plans setStep={setStep} step={step} city={city} setCity={setCity} cityData={cityData} date={date} setDate={setDate} setPlan={setPlan} planData={planData}  />,
        3: <Slot slot={slot} setStep={setStep} step={step} amount={amount} setAmount={setAmount}  date={date} setDate={setDate} plan={plan} setNumberOfPerson={setNumberOfPerson} setSlot={setSlot} numberOfPerson={numberOfPerson} city={city} />,
        4: <Decorations setStep={setStep} step={step} date={date} setDate={setDate} decoration={decoration} setDecoration={setDecoration} />,
        5: <Cakes eggless={eggless} setEggless={setEggless} setStep={setStep} step={step} cake={cake} setCake={setCake} />,
        6: <Addon setStep={setStep} step={step} addon={addon} setAddon={setAddon}  />,
        7: <Review eggless={eggless} name={name} setName={setName} email={email} setEmail={setEmail} mobile={mobile} setMobile={setMobile} setStep={setStep} step={step} decoration={decoration} cake={cake} addon={addon} numberOfPerson={numberOfPerson} date={date} city={city} slot={slot} amount={amount} plan={plan} />
    }



    return (
        stepMap[step]
    )
}

export default BookNow;